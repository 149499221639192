<template>
  <div class="header" :class="hideBg?'headBgfff':''">
    <div class="logo_img">
      <img @click="choosebtn(1)" src="../../assets/img/logo@2x.png" alt />
    </div>
    <div class="header_Tab">
      <span
        :class="[$store.state.headerTab==1?'choose_tab':'tab_item']"
        @click="choosebtn(1)"
        @mouseover="subhead=0"
      >
        <span>首页</span>
      </span>
      <span
        :class="[$store.state.headerTab==2?'choose_tab':'tab_item']"
        @click="choosebtn(2)"
        @mouseover="subhead=2"
      >
        <span>关于我们</span>
      </span>
      <span
        :class="[$store.state.headerTab==3?'choose_tab':'tab_item']"
        @click="choosebtn(3)"
        @mouseover="subhead=3"
      >
        <span>商业项目</span>
      </span>
      <span
        :class="[$store.state.headerTab==4?'choose_tab':'tab_item']"
        @click="choosebtn(4)"
        @mouseover="subhead=4"
      >
        <span>最新资讯</span>
      </span>
      <span
        :class="[$store.state.headerTab==5?'choose_tab':'tab_item']"
        @click="choosebtn(5)"
        @mouseover="subhead=5"
      >
        <span>联系我们</span>
      </span>
      <span @click="choosebtn(6)" @mouseover="subhead=0">
        <el-button class="search_icon" icon="el-icon-search" type="text" circle></el-button>
      </span>
    </div>
    <div v-if="subhead==2" class="subhead" @mouseleave="subhead=0">
      <span @click="togo(2,0)">公司简介</span>
      <span class="subhead_margin" @click="togo(2,1)">公司历程</span>
      <span class="subhead_margin" @click="togo(2,2)">公司架构</span>
      <span class="subhead_margin" @click="togo(2,3)">产品线规划</span>
      <span class="subhead_margin" @click="togo(2,4)">核心业务</span>
    </div>
    <div v-if="subhead==3" class="subhead" @mouseleave="subhead=0">
      <span @click="togo(3,0)">运营期项目</span>
      <span @click="togo(3,1)" class="subhead_margin">筹备期项目</span>
    </div>
    <div v-if="subhead==4" class="subhead" @mouseleave="subhead=0">
      <span @click="togo(4,0)">公司新闻</span>
      <span @click="togo(4,1)" class="subhead_margin">行业新闻</span>
    </div>
    <div v-if="subhead==5" class="subhead" @mouseleave="subhead=0">
      <span @click="togo(5,0)">人才招聘</span>
      <span @click="togo(5,1)" class="subhead_margin">联系方式</span>
      <span @click="togo(5,2)" class="subhead_margin">意见反馈</span>
      <span @click="togo(5,3)" class="subhead_margin">项目合作</span>
      <span @click="togo(5,4)" class="subhead_margin">供应商报名</span>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
export default {
  name: "app_header",
  inject: ["reload"],
	props: {
		headTitle:String,
	        hideBg: {
	          type: Boolean,
	          default: false
	        }
	},
  data() {
    return {
      // header_tab: 1,
      subhead: 0
    };
  },
  mounted() {
    console.log(this.$store.state.headerTab);
    // if (Cookies.get("headerTab")) {
    //   console.log(Cookies.get("headerTab"));
    //   this.header_tab = Cookies.get("headerTab");
    // }
  },
  methods: {
    choosebtn(tab) {
      this.$store.commit("updateTab", tab);
      console.log(this.$store.state.headerTab);
      // Cookies.set("headerTab", tab);
      switch (tab) {
        case 1:
          this.$router.push("/");
          this.toTop();
          this.reload();
          break;
        case 2:
          this.$router.push("/aboutUs");
          this.toTop();
          this.reload();
          break;
        case 3:
          this.$router.push("/Projects");
          this.toTop();
          this.reload();
          break;
        case 4:
          this.$router.push("/CompanyNews");
          this.toTop();
          this.reload();
          break;
        case 5:
          this.$router.push("/TalentConcept");
          this.toTop();
          this.reload();
          break;
        case 6:
          this.$router.push("/search");
          this.toTop();
          this.reload();
          break;

        default:
          break;
      }
    },
    togo(tab, subtab) {
      this.$store.commit("updateTab", tab);
      switch (tab) {
        case 2:
          this.header_tab = tab;
          this.$router.push("/aboutUs?subtab=" + subtab);
          this.reload();
          this.toTop();
          break;
        case 3:
          this.header_tab = tab;
          this.$router.push("/Projects?subtab=" + subtab);
          this.reload();
          this.toTop();
          break;
        case 4:
          this.header_tab = tab;
          this.$router.push("/CompanyNews?subtab=" + subtab);
          this.reload();
          this.toTop();
          break;
        case 5:
          this.header_tab = tab;
          this.$router.push("/TalentConcept?subtab=" + subtab);
          this.reload();
          this.toTop();
          break;

        default:
          break;
      }
    },
    toTop() {
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style lang="scss">
$vm_w_base: 1920;
$vm_h_base: 1080;
@function vm_w($px) {
  @return ($px / 1920) * 100vw;
}
.header:hover {
  background-color: #fff;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.08);
}
.header {
  width: 100%;
  height: vm_w(80);
  line-height: vm_w(80);
  position: fixed;
  top: 0;
  z-index: 999;
  background: linear-gradient(
    180deg,
    #ffffff,
    rgba(255, 255, 255, 0.95) 38%,
    rgba(255, 255, 255, 0.9) 55%,
    rgba(255, 255, 255, 0.75) 65%,
    rgba(255, 255, 255, 0.55) 74%,
    rgba(255, 255, 255, 0.3) 84%,
    rgba(255, 255, 255, 0.09) 92%,
    rgba(255, 255, 255, 0)
  );
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.08);
  .logo_img {
    width: vm_w(328);

    display: inline-block;
    margin-left: vm_w(364);
    cursor: pointer;
  }
  .logo_img img {
    width: 100%;
    align-items: center;
  }
  .header_Tab {
    display: inline;
    margin-left: vm_w(454);
  }

  .tab_item {
    padding: 0 vm_w(15);
    border-right: solid vm_w(2) #808080;
    font-size: vm_w(16);
    font-weight: 400;
    cursor: pointer;
  }
  .choose_tab {
    padding: 0 vm_w(15);
    border-right: solid vm_w(2) #808080;
    font-size: vm_w(16);
    font-weight: 400;
    cursor: pointer;
    color:$chooseColor;
    width: 100%;
  }
  .choose_tab span {
    border-bottom: solid vm_w(4);
    padding-bottom: vm_w(7);
  }

  .tab_item:hover {
    color: $chooseColor;
  }
  .subhead {
    width: 100%;
    height: vm_w(48);
    line-height: vm_w(48);
    background: #f1f1f1;
    z-index: 10;
    position: absolute;
    bottom: vm_w(-48);
    opacity: 1;
    text-align: center;
    font-size: vm_w(14);
    font-weight: 400;
    color: #666666;
    cursor: pointer;
  }
  .subhead span {
    display: inline-block;
    height: 100%;
  }
  .subhead_margin {
    margin-left: vm_w(123);
  }
  .subhead span:hover {
    color: $chooseColor;
  }
  .search_icon {
    color: #333 !important;
    font-size: vm_w(15);
    & .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: $chooseColor !important;
    }
  }
  .search_icon:hover {
    color: #0c46a8 !important;
  }
}
.headBgfff{
	background-color: #fff;
}
</style>